import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-polyglot';

import withSetLocale from '../../components/i18n/withSetLocale';
import Layout from '../../components/layout';
import OrganizationPage from '../../components/OrganizationPage';

const Organization = ({ data, pageContext: { locale, messages } }) => (
    <Layout>
        <I18n locale={locale} messages={messages}>
            <OrganizationPage locale={locale} data={data} />
        </I18n>
    </Layout>
);

Organization.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
};

export default withSetLocale()(Organization);

export const pageQuery = graphql`
    query OrganizationQuery {
        headerImage: file(relativePath: { eq: "team.jpeg" }) {
            publicURL
            absolutePath
            name
            childImageSharp {
                fluid(maxWidth: 1280, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        team: allMdx(filter: { fileRelativePath: { glob: "**/team/**" } }) {
            edges {
                node {
                    parent {
                        ... on File {
                            name
                        }
                    }
                    id
                    frontmatter {
                        name
                        fullname
                        link
                        picture
                        biography
                        retired
                        role
                    }
                }
            }
        }
    }
`;
